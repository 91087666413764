import { fb, validators } from "../../../lib/form";

export function getContactModel(data) {
  let hearFromList = ["Internet", "Friend", "Media", "Other"].map((x) => {
    return { name: x, value: x };
  });
  hearFromList = [{ name: "Please Select", value: "" }, ...hearFromList];

  const model = fb.group({
    name: [
      data.name || "",
      [validators.Required()],
      { label: null, type: "text" },
    ],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      { label: null, type: "email" },
    ],
    phone: [
      data.phone || "",
      [validators.Required()],
      { label: null, type: "text" },
    ],
    hearFrom: [
      data.hearFrom || "",
      [],
      { label: null, type: "select", options: hearFromList },
    ],
    enquiry: [data.enquiry || "", [], { label: null, type: "textarea" }],
    attachments: [
      data.attachments || "",
      [],
      { label: "Attachments", type: "file" },
    ],
  });
  return model;
}
