import { css } from "@emotion/core";
import { mq } from "../../cssInJs";
export default {
  button: css(
    mq({
      position: "absolute",
      left: "1rem",
      lineHeight: "80px",
      color: "#ddd",
      fontSize: ["16px", null, "20px"],
    })
  ),
};
