import React, { useEffect, useRef } from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";

export function Weather(props) {
  const item = props.item;
  const code = cmsUtils.payload(item, "Code");
  const cssClass = utils.classNames(
    "cms_item",
    "weather",
    item.cssClass || item.anchorName || ""
  );
  const containerRef = useRef(null);

  useEffect(() => {
    const currentContainer = containerRef.current;
    if (currentContainer && code) {
      currentContainer.innerHTML = code;
      const initWeatherWidget = () => {
        if (window.__weatherwidget_init) {
          window.__weatherwidget_init();
        }
      };
      const scriptTags = currentContainer.getElementsByTagName("script");
      Array.from(scriptTags).forEach((scriptTag) => {
        const newScript = document.createElement("script");
        Array.from(scriptTag.attributes).forEach((attr) => {
          newScript.setAttribute(attr.name, attr.value);
        });
        newScript.appendChild(document.createTextNode(scriptTag.innerHTML));
        scriptTag.parentNode.replaceChild(newScript, scriptTag);
      });
      setTimeout(initWeatherWidget, 100);
    }
    return () => {
      if (currentContainer) {
        currentContainer.innerHTML = "";
      }
    };
  }, [code]);

  return (
    <div
      className={cssClass}
      data-cms-item-id={item.itemId}
      id={item.anchorName}
      ref={containerRef}
    ></div>
  );
}
