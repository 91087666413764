import React from "react";
import env from "../../../env";
import { usePost } from "../../../components";
import { useForm } from "../../../form";
import { Button, ErrorMessage } from "../../../components";
import { getContactModel } from "./contactFormModel";
import { gtm } from "../../../lib/tracking";

export function Contact(props) {
  const emailReceiver = props.emailReceiver;
  const fileAttachment = props.fileAttachment || "false";
  const mailchimpApiKey = props.mailchimpApiKey;
  const mailchimpAudienceKey = props.mailchimpAudienceKey;

  const model = getContactModel({});
  const form = useForm(model, { usePlaceholder: true });
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [mailChimpError, setMailChimpError] = React.useState();
  const post = usePost();

  const mailChimpErrorMemo = React.useMemo(() => {
    return mailChimpError;
  }, [mailChimpError]);

  function onSubmit(e) {
    setMailChimpError(null);
    form.validateForm(e, () => {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(env.reCaptchaSiteKey, { action: "submit" })
          .then(function (token) {
            const value = {
              ...form.getValue(),
              emailReceiver: emailReceiver,
              mailchimpApiKey: mailchimpApiKey,
              mailchimpAudienceKey: mailchimpAudienceKey,
              grecaptchaToken: token,
            };

            var files = form.getFiles();
            var formData = new FormData();
            formData.append("jsonData", JSON.stringify(value));
            files.forEach((file) => {
              (file.files || []).forEach((uploadFile) => {
                formData.append(file.name, uploadFile);
              });
            });
            post.sendFormData(env.apiBase + "/api/contact/send", formData);
            setSendingStatus("pending");
          });
      });
    });
  }

  if (post.done() && sendingStatus === "pending") {
    var value = form.getValue();
    gtm.sendEvent("contactFormSubmitted", { contactEmail: value.email });
    form.reset(model);
    setSendingStatus(null);

    if (
      post.response.results &&
      post.response.results.MailChimpError &&
      post.response.results.MailChimpError !== ""
    ) {
      setMailChimpError(
        "Mail Chimp Error : " + post.response.results.MailChimpError
      );
    }
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <form className='contactForm'>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}>Your name: *</label>
          {render("name")}
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}>Your Email: *</label>
          {render("email")}
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}>Contact Number: *</label>
          {render("phone")}
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}>
            How did you hear about us
          </label>
          <div class='custom-select'>{render("hearFrom")}</div>
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <label style={{ marginBottom: "0.4rem" }}>
            Your Message or Question
          </label>
          {render("enquiry")}
        </div>
      </div>

      {fileAttachment === "true" && (
        <div className='form-row'>
          <div className='col-md-12 mb-2'> {render("attachments")} </div>
        </div>
      )}

      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <div className='actions'>
            <Button onClick={onSubmit} status={post.status}>
              Submit
            </Button>
            <ErrorMessage
              errors={form.errors}
              summaryMessage='Please review the errors.'
            />
            <ErrorMessage errors={post.errors} />
            <ErrorMessage errors={mailChimpErrorMemo} />
            {post.done() && (
              <span
                style={{
                  marginLeft: "1.5rem",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                Successfully sent!
              </span>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
