/** @jsx jsx */
import { jsx } from "@emotion/core";
import utils from "utils";
import style from "./fullPanelGoogleMapStyle";
import cmsUtils from "../../cmsUtils";

export function FullPanelGoogleMap(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const heightSet = cmsUtils.payload(item, "HeightSet") || "standard";
  const code = cmsUtils.payload(item, "Code");
  const cssClass = utils.classNames(
    "cms_item",
    "full__panel__google__map__embed",
    item.cssClass || item.anchorName || ""
  );
  const getHeight = () => {
    switch (heightSet) {
      case "thin":
        return "300px";
      case "large":
        return "600px";
      default:
        return "450px";
    }
  };

  return (
    <section
      className={cssClass}
      css={style.full__panel__google__map__embed}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <div
        css={[
          style.full__panel__google__map__embed__con,
          { height: getHeight() },
        ]}
        className='container full__panel__google__map__embed__con'
      >
        <div dangerouslySetInnerHTML={{ __html: code }} />
      </div>
    </section>
  );
}
